import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	isBlank,
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {
	data: function () {
		return {
			units: [],
			from: undefined,
			till: undefined,
		};
	},
	// props: {
	// 	filter: Object,
	// 	sorting: String,
	// 	max: String
	// },
	beforeMount: function () {
		var results = this.$el.getElementsByClassName('unit-list-section');
		if (results.length) {
			this.resultFields = findResultFields(results[0].innerHTML, this.$el);
		}

		var req = {

			/* fields: this.resultFields, */

			fields: {
				"u.path": {
					"field": "path",
					"filter": "vlang"
				},
				"u.images": {
					"field": "images"
				},
				"u._id": {
					"field": "_id"
				},
				"u.idxImage": {
					"field": "idxImage"
				},
				"u.name": {
					"field": "name",
					"filter": "vautolang"
				},
				"u.calc.discountName": {
					"field": "calc.discountName"
				},
				"u.pricelist": {
					"field": "pricelist"
				},
				"u.in+units": {
					"field": "in+units"
				},
				"u.beachdistance": {
					"field": "beachdistance",
					"filter": "vautokm"
				},
				"u.squareMeters": {
					"field": "squareMeters"
				},
				"u.bedrooms": {
					"field": "bedrooms"
				},
				"u.wifi": {
					"field": "wifi"
				},
				"u.pets": {
					"field": "pets"
				},
				"u.nonsmoking": {
					"field": "nonsmoking"
				},
				"u.balcony": {
					"field": "balcony"
				},
				"u.p_15518": {
					"field": "p_15518"
				},
				"u.privateparking": {
					"field": "privateparking"
				},
				"u.stars": {
					"field": "stars"
				},
				"u.floor": {
					"field": "floor"
				},
				"u.feedback": {
					"field": "feedback"
				},

				"u.alternatives": {
					"field": "alternatives"
				},
				"u.calc": {
					"field": "calc"
				},
				"u.calc.oTotal": {
					"field": "calc.oTotal",
					"filter": "vcurrency"
				},
				"u.minRent": {
					"field": "minRent",
					"filter": "vcurrency"
				},
				"u.offers": {
					"field": "offers"
				},
				"u.regionName": {
					"field": "regionName",
					"filter": "vlang"
				},
				"u.facilityName": {
					"field": "facilityName",
					"filter": "vautolang"
				},
				"u.type": {
					"field": "type",
					"filter": "vsoption",
					"filterparams": [
						"hpunit.type"
					]
				},
				"u.calc.total": {
					"field": "calc.total",
					"filter": "vcurrency"
				},
				"u.loc.coordinates": {
					"field": "loc.coordinates"
				}
			},

			filter: {
				unitgroupid: 35130,
				from: this.getFrom(),
				till: this.getTill(),
				nights_min: 4,
				nights_max: 14,
			},
			max: 6,
			sorting: 'random',
			alternatives: true,
			fuzzy: true
		};

		doReq('searchUnits', req)
			.then((res) => {
				this.units = res.units;
			});

		//console.log("req::", req);
	},

	updated: function () {
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();
	},
	methods: {
		getSearchUrl: function () {
			var url = './s#?unitgroupid=35130&from=' + this.from + '&till=' + this.till + '&nights_min=4&nights_max=14';
			return url;
		},
		getUnitSearchPath: function (path, o) {

			var searchKeys = ['from', 'till', 'adults', 'children', 'babys', 'pets', 'petsCount'];
			var params = [];
			for (let sp of searchKeys) {
				if (o && o[sp]) {
					params.push(sp + '=' + o[sp]);
				}
			}

			if (params.length) {
				path += '#?' + params.join('&');
			}
			return path;


		},
		getAlternatePeriod: function (u) {
			var result = null;
			if (u.matchingPeriods && u.matchingPeriods.length > 0) {
				result = {
					from: u.matchingPeriods[0].fromdate,
					till: u.matchingPeriods[0].tilldate
				};
				return result;
			}
			if (u.alternatives && u.alternatives.length > 0) {
				result = {
					from: u.alternatives[0].fromdate,
					till: u.alternatives[0].tilldate
				};
				return result;
			}
			return result;
		},

		getTill: function () {
			var today = DateEx.interfaceFormat(DateEx.getToday());
			var till = DateEx.interfaceFormat(DateEx.plusDays(today, 14));
			this.till = till;
			return till;


		},
		getFrom: function () {
			var today = DateEx.interfaceFormat(DateEx.getToday());
			var from = DateEx.interfaceFormat(DateEx.plusDays(today, 2));
			this.from = today;
			return from;

		},
		getUnitSearchTarget: function (id) {
			if (window.screen.width < 768) {
				//probably phone?
				return '_self';
			} else {
				return 'unit_' + id;
			}
		},
	}

};