export default {
	data: {
		selectedImageIndex: undefined
	},
	beforeMount: function () {
		//console.warn("ha", window.location.search);
		var url = new URL(window.location.href);
		var params = {};
		for (let key of url.searchParams.keys()) {
			//console.log("p", key, url.searchParams.get(key));
			params[key] = url.searchParams.get(key);

		}
		this.searchParams = params;
	},
	computed: {

		mailto: function () {
			return `mailto:${this.emailAddress}`;
		},
		emailAddress: function () {
			return (VOFFICE.contactEmail || ['info', 'test.de']).join('@');
		}
	},

	methods: {

		searchTab: function (uniqueid) {
			if (!uniqueid) {
				uniqueid = '';
			}
			console.log('search Tab clicked...........' + uniqueid);


			document.getElementById('obj-tab' + uniqueid).classList.remove('active');
			document.getElementById('search-tab' + uniqueid).classList.remove('active');
			document.getElementById('search-obj-form' + uniqueid).style.display = 'none';
			document.getElementById('search-form' + uniqueid).style.display = 'flex';
			document.getElementById('search-tab' + uniqueid).classList.add('active');
			console.log('search tab clicked end');

		},
		objectTab: function (uniqueid) {
			if (!uniqueid) {
				uniqueid = '';
			}
			console.log('objecctTab clicked...........' + uniqueid);

			/*$('#obj-tab').removeClass('active');
			$('#search-tab').removeClass('active');
			$('#obj-tab').addClass('active');*/

			document.getElementById('obj-tab' + uniqueid).classList.remove('active');
			document.getElementById('search-tab' + uniqueid).classList.remove('active');
			document.getElementById('search-form' + uniqueid).style.display = 'none';
			document.getElementById('search-obj-form' + uniqueid).style.display = 'flex';
			document.getElementById('obj-tab' + uniqueid).classList.add('active');
			console.log('obj tab clicked end');

		}
	},

};